import { Adapter, createClient } from 'contentful-management'

const US_LOCALE = 'en-US';
const parseLibraries = (entries: { fields: any; }[]) => entries?.map(({ fields }) => fields.name[US_LOCALE]);

export const getLibraries = async ({ apiAdapter, environmentId, spaceId, entryId, parentLibrary }: {
 apiAdapter: Adapter, environmentId: string, spaceId: string, entryId: string, parentLibrary?: string 
}) => {
 const client = await createClient({ apiAdapter }, { type: 'plain', defaults: {
  environmentId,
  spaceId,
 } });

 const currentEntry = await client.entry.get({
  entryId,
 });

 const { isPlaceholder } = currentEntry.fields;
 if (isPlaceholder[US_LOCALE]) {
  return [];
 }

 const libraries = await client.entry.getMany({
  spaceId,
  environmentId,
  query: {
   content_type: 'libraries',
   'fields.isPlaceholder': true,
  }
 });

 return parseLibraries(libraries.items);
}