import React, { useState, useEffect } from 'react';
import { Spinner, Select } from '@contentful/f36-components';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { getLibraries } from '../utils/api';

const Field = () => {
  const sdk = useSDK<FieldExtensionSDK>();
  const [selectedValue, setSelectedValue] = useState(sdk.field.getValue());
  const [isLoadingValue, setIsLoadingValue] = useState(true);
  const [libraries, setLibraries] = useState<string[]>([]);

  useEffect(() => {
    sdk.window.startAutoResizer();
  }, [sdk.window]);

  useEffect(() => {
    const fetchLibraries = async () => {
      try {
        const currentLibraries = await getLibraries({
          apiAdapter: sdk.cmaAdapter,
          environmentId: sdk.ids.environment,
          spaceId: sdk.ids.space,
          entryId: sdk.ids.entry,
          parentLibrary: selectedValue,
        });
        setLibraries(currentLibraries);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingValue(false);
      }
    };

    fetchLibraries().catch(null);
  }, [sdk.cmaAdapter, sdk.ids.entry, sdk.ids.environment, sdk.ids.space, selectedValue]);

  const renderOptions = () => libraries?.map((library: string) => (
    <Select.Option value={library} key={library}>{library}</Select.Option>
  ));

  const handleSelectChange = ({ target: { value }}: { target: { value: string }}) => {
    setSelectedValue(value);
    sdk.field.setValue(value);
  };

  if (isLoadingValue) {
    return <Spinner />
  }

  return (
    <Select value={selectedValue} onChange={handleSelectChange}>
      <Select.Option value={undefined}>None</Select.Option>
      {renderOptions()}
    </Select>
  );
};

export default Field;
